import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, ModuleKeywords, PolicyBodyTemplateDto } from '@shared/classes';
import { ViewModeService } from '@shared/services';

@Component({
  selector: 'app-policy-body-template-item-form',
  templateUrl: './policy-body-template-item-form.component.html',
  styleUrl: './policy-body-template-item-form.component.scss',
})
export class PolicyBodyTemplateItemFormComponent extends BaseForm<PolicyBodyTemplateDto> implements OnInit {

  @Input() set templateSections(value) {
    if (value) {
      this.formGroup.controls.templateSections.patchValue(value);
    }
  }

  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, ModuleKeywords.PolicyBodyTemplate);
  }

  ngOnInit(): void { }

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.formGroup.patchValue({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      description: new FormControl(null),
      templateSections: new FormControl([]),
    });
  }
}
